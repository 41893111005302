/* Sass imports */

@use 'sass:color';

/* Color palette */

$purple: #a497ff;
$purple-light: #dcd7ff;
$purple-gray: #a4ade6;

$black: #35384a;
$gray: #5b6077;
$light-gray: #c4c4c4;
$white: #f7f7f7;

$absolute-black: #000;
$absolute-white: #fff;

$background-mask: rgba(0, 0, 0, 0.65);

/* Color aliases */

$primary-color: $purple;
$primary-color-light: $purple-light;
$primary-color-placeholder: $purple-gray;
$primary-color-text: $white;
$primary-color-dull: color.adjust($primary-color-text, $lightness: -30%);
