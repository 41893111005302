@use "variables";

/* Font families */

.font-default {
  color: variables.$primary-color-text;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 400;
}

.font-heading {
  color: variables.$primary-color-text;
  font-family: 'Oswald', 'Open Sans', Arial, sans-serif;
  font-weight: 700;
}

.font-subheading {
  color: variables.$primary-color-text;
  font-family: 'Noto Serif', Georgia, serif;
  font-weight: 400;
}

.font-code {
  color: variables.$primary-color-text;
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

/* Links */

a {
  color: variables.$primary-color-text;
  opacity: .8;
  text-decoration: none;
  transition: opacity .2s linear;
}

a:hover {
  opacity: 1;
  text-decoration: none;
}

.links {
  @extend .font-code;

  font-size: 1em;
}

.links a:not(:last-of-type) {
  margin-right: 2em;
}

/* Forms */

input {
  @extend .font-default;

  background-color: transparent;
  border-color: variables.$primary-color-text;
  border-radius: 0px;
  border-style: none none solid none;
  border-width: 0.1em;
  box-shadow: 0em 0.15em variables.$absolute-black;
  color: variables.$primary-color-text;
  font-size: 1em;
  letter-spacing: 0.015em;
}

input::placeholder {
  color: variables.$primary-color-text;
  opacity: 0.3;
}

/* Modifiers */

b {
  font-weight: bolder;
}

.hide {
  display: none;
}

.blur {
  filter: blur(15px);
}

.code {
  @extend .font-code;
}

/* Defaults */

body {
  @extend .font-default;

  background-color: variables.$black;
}

::selection {
  background: variables.$primary-color-light;
}
